import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ReactComponent as PortfolioCloseIcon } from '@icons/custom/portfolio-close-light.svg';
import { ReactComponent as PortfolioCreateIcon } from '@icons/custom/portfolio-create-light.svg';
import { ReactComponent as UserFriendIcon } from '@icons/custom/user-friend-light.svg';
import { ReactComponent as UserDeleteIcon } from '@icons/custom/user-delete-light.svg';
import { BodyMedium } from '@components/styled/Typography';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { ActivityType } from '@entities/current-user';
import Icon from '@shared/ui/icons/Icon';
import ActivityInfoLink from './ActivityInfoLink';
const ActivityEventContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xm,
    alignItems: 'center',
}));
const IconStyled = styled(Icon, { shouldForwardProp: propName => propName !== 'backgroundColor' })(props => ({
    padding: props.theme.spacing_sizes.xs * 1.25,
    backgroundColor: props.backgroundColor || 'transparent',
    borderRadius: '50%',
    maxHeight: 40,
    boxSizing: 'border-box',
}));
const EventText = styled(ContainerColumn)(() => ({
    height: 46,
}));
const EventDescription = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.m,
    fontSize: 15,
    lineHeight: '26px',
}));
const TimeText = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.secondary,
    fontSize: 12,
    lineHeight: '20px',
}));
const ActivityEvent = ({ event }) => {
    const theme = useTheme();
    const getIcon = () => {
        switch (event.activityType) {
            case ActivityType.ClosePortfolio:
                return (_jsx(IconStyled, { IconComponent: PortfolioCloseIcon, size: 20, backgroundColor: theme.palette.error.light, keepOriginalColor: true }));
            case ActivityType.CreatePortflio:
                return (_jsx(IconStyled, { IconComponent: PortfolioCreateIcon, size: 20, backgroundColor: theme.palette.success.light, keepOriginalColor: true }));
            case ActivityType.StartFollowing:
                return (_jsx(IconStyled, { IconComponent: UserFriendIcon, size: 20, color: theme.palette.primary.main, backgroundColor: theme.customColors.shades.primaryLight }));
            case ActivityType.StopFollowing:
                return (_jsx(IconStyled, { IconComponent: UserDeleteIcon, size: 20, color: theme.palette.primary.main, backgroundColor: theme.customColors.shades.primaryLight }));
            default:
                return null;
        }
    };
    const eventDescription = () => {
        switch (event.activityType) {
            case ActivityType.ClosePortfolio:
                return Trans({
                    i18nKey: 'profile.tabs.activity_events_list.close_portfolio',
                    ns: 'translation',
                    components: {
                        Link: _jsx(ActivityInfoLink, { eventType: event.activityType, subjectId: event.subjectId, subjectName: event.subjectName || '' }),
                    },
                });
            case ActivityType.CreatePortflio:
                return Trans({
                    i18nKey: 'profile.tabs.activity_events_list.create_portfolio',
                    ns: 'translation',
                    components: {
                        Link: _jsx(ActivityInfoLink, { eventType: event.activityType, subjectId: event.subjectId, subjectName: event.subjectName || '' }),
                    },
                });
            case ActivityType.StartFollowing:
                return Trans({
                    i18nKey: 'profile.tabs.activity_events_list.start_following',
                    ns: 'translation',
                    components: {
                        Link: _jsx(ActivityInfoLink, { eventType: event.activityType, subjectId: event.subjectId, subjectName: event.subjectName || '' }),
                    },
                });
            case ActivityType.StopFollowing:
                return Trans({
                    i18nKey: 'profile.tabs.activity_events_list.stop_following',
                    ns: 'translation',
                    components: {
                        Link: _jsx(ActivityInfoLink, { eventType: event.activityType, subjectId: event.subjectId, subjectName: event.subjectName || '' }),
                    },
                });
            default:
                return null;
        }
    };
    return (_jsxs(ActivityEventContainer, { children: [getIcon(), _jsxs(EventText, { children: [_jsx(EventDescription, { children: eventDescription() }), event.timestamp && _jsx(TimeText, { children: format(new Date(event.timestamp), 'HH:mm') })] })] }));
};
export default ActivityEvent;
